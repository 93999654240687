import React, { useContext, useState } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import MinistryItem from "../ministries/ministry-item"
import Button2 from "../button-generic"
//import { useStaticQuery, graphql } from "gatsby"
//import { useBreakpoint } from "gatsby-plugin-breakpoints"
import PageTopContent from "../home-top-content"
import { useFlexSearch } from "react-use-flexsearch"
import Filters from "../search/filters"
import Section from "../section"
import ServiceItem from "../services/service-item"

const MinistriesPage = (props) => {


  const {
    post,
    bannerHeight="408px",
    //localSearchPages,
    ministries,
    buttonBanner,
    lang,
    services
  } = props
  const { t } = useContext(LanguageContext)
  //const breakpoints = useBreakpoint()

  const { search } = typeof window !== 'undefined' ? window.location : '';
  const query = new URLSearchParams(search).get('s')
  const [searchQuery, setSearchQuery] = useState(query || '');

  //const index = localSearchPages?.index
  //const store = localSearchPages?.store


  //const results2 = useFlexSearch(searchQuery, index, store);

  //const posts = searchQuery ? unFlattenResults(results2) : ministries;

    const posts = ministries;

    const getTotalSubministries = (subministries) => {
      let count = 0
      if (subministries && Array.isArray(subministries)) {
        count = subministries.filter(subministry => subministry?.field_show_on_here_for_you === 'no').length
      }
      return count
    }

  return(
    <div className="page-ministries">
    <Banner
      post={post}
      height={bannerHeight}
      //buttonBanner={buttonBanner}
      hasButton={true}
      backgroundColor="bordeaux"
      anchorButton="/serveix#section-services"
      anchorButtonText={buttonBanner}
    />

    <PageTopContent
      lang={lang}
      text={<PageTopContentText lang={lang} />}
      buttonTitle="Colossencs 3:23"
      isButton={false}
      className="row home-top-content m-b-108 small-m-b-42"
     />

     <section className="main-ministries">
     <div className="container p-0">
      <Filters
           //button1={<Button2 className="btn-all-ministries p-0" title={t("Tots els Serveis")} />}
           //button2={<Button2 className="btn-dropdown" type="dropdown" title={t("Per Ministeri")} options={ministries} />}
           buttons={[
           <Button2
            className="btn-all-ministries p-0"
            title={t("Tots els Serveis")}
            wrapperClass="col-2- p-l-0 p-r-0 all-ministries-wrapper m-r-32"
            />,
           <Button2
            className="btn-dropdown filter-ministry"
            type="dropdown"
            title={t("Per Ministeri")}
            options={ministries}
            wrapperClass="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
            />,
            ]}
           searchQuery={searchQuery}
           setSearchQuery={setSearchQuery}
           action="/serveix"
           marginbottom="m-b-88 small-m-b-24"
           hasSearch={true}
       />

       <h2 className="H2-Medium-C---bordeaux p-b-88 medium-m-b-40 small-m-b-16 medium-p-b-0 small-p-b-0">
         {t("Tots els Ministeris")}
       </h2>
       <div className="row">
       {posts && posts?.edges?.length && posts?.edges?.map(({node}, index) => {
         return(
             <MinistryItem
               classes="col-md-4 col-lg-3 col-6 ministry-item m-b-56"
               title={node.title}
               url={node?.path?.alias}
               image={node?.relationships?.field_image?.image_style_uri?.large}
               key={index}
               buttonType={node?.field_form?.drupal_internal__target_id ? "modal" : "url"}
               formId={node?.field_form?.drupal_internal__target_id}
               formData={node?.relationships?.field_form}
               transparent={true}
               totalSubministries={getTotalSubministries(node?.relationships?.subministries)}
             />
         )
         })
        }
        </div>
     </div>
     </section>
     <Section
      className="section-services background-beig p-t-80 small-p-t-24"
      id="section-services"
      headingColors="green-dark"
      title="Oportunitats de servei"
      headingClassName="H2-Medium-C---green-dark p-b-88 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0"
      t={t}
     >
     <div className="container p-0">
       <Filters
            //button1={<Button2 className="btn-all-ministries p-0" title={t("Tots els Ministeris")} />}
            //button2={<Button2 className="btn-dropdown" type="dropdown" title={t("Per Ministeri")} options={ministries} />}
            buttons={[
              <Button2 className="btn-all-services p-0" title={t("Tots els Serveis")} />,
              <Button2 className="btn-dropdown" type="dropdown" title={t("Per Ministeri")} options={ministries} />,
              <Button2 className="btn-dropdown" type="dropdown" title={t("Díes")} options={ministries} />,
              <Button2 className="btn-dropdown" type="dropdown" title={t("Hora inici")} options={ministries} />,
              <Button2 className="btn-dropdown" type="dropdown" title={t("Hora final")} options={ministries} />,
              <Button2 className="btn-dropdown" type="dropdown" title={t("Durada")} options={ministries} />,
              <Button2 className="btn-dropdown" type="dropdown" title={t("Freqüència")} options={ministries} />
             ]}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            action="/serveix"
            marginbottom="m-b-67"
        />
        <div className="services-list row">
          {services && services.edges && services.edges.map(({node}, index) => {
            return(
              <div className="col-sm-3 col-md-6 col-lg-3 col-12 service-item m-b-56">
                <ServiceItem
                  classes="card card-unida"
                  title={node.title}
                  datehour={node?.field_when}
                  ministry={node?.relationships?.field_ministry?.title}
                  body={node?.body?.value}
                  image={node?.relationships?.field_image?.image_style_uri?.large}
                  key={index}
                  buttonType={node?.field_form?.drupal_internal__target_id ? "modal" : "url"}
                  formId={node?.field_form?.drupal_internal__target_id}
                  formData={node?.relationships?.field_form}
                  transparent={true}
                  totalSubministries={node?.relationships?.subministries?.length}
                  url={node?.path?.alias}
                />
              </div>
            )
          })
          }
        </div>
     </div>

     </Section>

    </div>
  )
}










const PageTopContentText = ({lang}) => {
  if(lang === "ca"){
    return(
      <div className="p-t-104 small-p-t-32">
          <div>“Sigui quin sigui el vostre treball, <span className="fill-backgrond">feu-lo de cor</span>, pel Senyor i no pels homes".
           </div>
           <div>
           </div>
      </div>
    )
  }else{
    return(
      <div>
          <div>“Sigui quin sigui el vostre treball, <span className="fill-backgrond">feu-lo de cor</span>, pel Senyor i no pels homes".
           </div>
      </div>
    )
  }
}



export const unFlattenResults = (results) =>{
  return results.map(post => {
    const { summary, alias, title } = post;
      return { alias, body: { title, summary }
    };
  });
}



 export default MinistriesPage
