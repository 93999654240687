import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Button from "../button-generic"
import PropTypes from "prop-types"
import ModalForm from "../modal/modal-form"
import ButtonModal from "../buttons/button-cta-top-content"
//import modalBackgroundImage from "../../assets/images/modal/modal-header-image-prayer.jpg"
import ServicesDayHour from "./services-day-hour"
import ServicesMinistryItem from "./services-ministry-item"


 const ServiceItem = (props) => {

   const {
     title,
     datehour,
     ministry,
     image,
     body,
     classes,
     url,
     columns,
     buttonType,
     formId,
     formData,
     transparent,
     totalSubministries
   } = props

   const { t } = useContext(LanguageContext)

   if(columns == 2 ){
     return (
       <div></div>
     )
   }
   return (
      <div className={classes}>
      {image &&
         <div>
           <div className={`${transparent ? '' : 'background-beig'} ministry-image`}>
             <img
               src={image}
               alt={title}
               className="m-r--24"
             />
           </div>
         </div>
       }
       <div className="col-md-6- m-w-507-">
         <h2 className="title ministry-title m-b-8">
          {title}
         </h2>
         <ServicesDayHour
          datehour={datehour}
          />
          <ServicesMinistryItem
           ministry={ministry}
           />
         <div className="ministry-desc">
         <div>
          <strong>
          {t("Inscripcions necessàries:")}
          </strong>
          2
         </div>
         <div>
          <strong>
          {t("Durada:")}
          </strong>
          1h
         </div>
         {
           totalSubministries
           ?
           <TotalSubministries
             totalSubministries={totalSubministries}
             t={t}
             className="subministries-count"
            />
           :
           ""
          }
          <div><span className="desc-label"><strong>{t("Descripció:")}</strong></span>
             <span className="m-b-16" dangerouslySetInnerHTML={{__html: body}}/>
           </div>
          </div>
          {
            buttonType === "modal"
            ?
            <ButtonModal
             title={t("Veure més")}
             backgroundimage={props?.image}
             formId="care_for_vulnerable_families"
             formData={formData}
             modalTitle=""
             modalDescription=""
             modalFooter=""
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more Normal-Bold-L---bordeaux right m-r-24"
             />
            :
            <Button
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more Normal-Bold-L---bordeaux right m-r-24 hide"
             onClick={() => navigate(url)}
             />
          }
       </div>
       </div>
   )
 }

 const TotalSubministries = (props) => {

   const { totalSubministries, t, className } = props
   if(!totalSubministries){
     return
   }
   return(
     <div className={className}>
     {
       totalSubministries == 0
       ?
       <span>{totalSubministries} {t('projectes')}</span>
       :
       totalSubministries == 1
       ?
       <span>{totalSubministries} {t('projecte')}</span>
       :
       <span>{totalSubministries} {t('projectes')}</span>
      }
      </div>
   )

 }

 const useDataFormModal = () => {
   return useStaticQuery(graphql`
      query helpFamiliesQuery {
        form_visit_us: webformWebform(drupal_internal__id: {eq: "visit_us_webform"}) {
          drupal_internal__id
          langcode
          elements {
            name
            type
            attributes {
              name
              value
            }
          }
        }
      }
    `)
 }

 ServiceItem.propTypes = {
   comumns: PropTypes.number,
   buttonType: PropTypes.string,
   formId: PropTypes.string,
   transparent: PropTypes.bool,
   totalSubministries: PropTypes.number
 }

 ServiceItem.defaultProps = {
   comumns: 1,
   buttonType: `url`,
   formId: ``,
   transparent: false,
   totalSubministries: 0
 }

 export default ServiceItem
