import React, { useContext } from "react"
import Video from "../video"
import { LanguageContext } from "../../context"
import Button from "../buttons/button-cta-top-content"
import Utils from "../../utils"
import moment from "moment"
import modalBackgroundImage from "../../assets/images/modal/modal-header-image-prayer.jpg"
import Banner from "../banner"

const WatchPage = (props) => {

  const { post, lang, events, bannerHeight="408px" } = props
  const { t } = useContext(LanguageContext)
  const sunday = useNextSunday(lang)
  //const backgroundImage = post?.relationships?.field_image?.image_style_uri?.large;
  const getUrl = () => {
    return events?.field_url_online_event?.uri ? events?.field_url_online_event?.uri : 'https://www.youtube.com/embed/oncBeLgTto8';
  }

  return(
    <>
    <Banner
      post={post}
      height={bannerHeight}
    />
    <div className="container p-0 p-t-28 p-b-80">
    <div className="row">
        <div className="col-sm-9 left-section">
          <div className="row">
            <div className="col-sm-12">
            <Video
              videoSrcURL={getUrl()}
              videoTitle={post?.title}
              thumbnail={events?.relationships?.field_youtube_image?.image_style_uri?.wide}
            />
            </div>
            {/*
            <div className="col-sm-12">
              More content
            </div>
           */}
          </div>
        </div>
        <div className="col-sm-3 sidebar">
          <div className="block-1 m-b-24">
            <h3 className="Normal-Medium-L---black sidebar-title">
              {t("Proper Culte")}
            </h3>
            <p className="Normal-Light-L---grey-dark">
              {t(sunday)}
            </p>
          </div>

          <div className="block-2 m-b-24 youtube-channel">
            <h3 className="Normal-Medium-L---black sidebar-title">
              {t("Canal de Youtube")}
            </h3>
            <p className="Normal-Light-L---grey-dark">
              <a
                className="green"
                href="https://www.youtube.com/UnidaTerrassa"
                target="_blank"
                rel="noreferrer"
                >youtube.com/UnidaTerrassa</a>
            </p>
          </div>

          <div className="block-3 need-prayer">
            <h3 className="Normal-Medium-L---black sidebar-title">
              {t("Necessites pregària?")}
            </h3>
            <p className="Normal-Light-L---grey-dark">
              {t("No importa al que t'enfrontis, ens encantaria pregar per tu!")}
            </p>
            <Button
              title={t("Demanar pregària")}
              backgroundimage={modalBackgroundImage}
            />
          </div>
      </div>
    </div>
    </div>
    </>
  )
}

const useNextSunday = (lang) => {
  moment.locale(lang)
  const nextSunday = Utils.getSundayOfCurrentWeek()
  const day = moment(nextSunday).format('D')
  const month = moment(nextSunday).format('MMMM')
  const de = Utils.startWithVowel(month,lang) ? "d'" : lang === "en" ? "" : "de " //check if its english
  const hourDayMonth = `${day} ${de}${month}, 11:00`;
  return hourDayMonth;
}

 export default WatchPage
